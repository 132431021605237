import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '../../../../environments/environment';
import { API_URL } from '../../../shared/consts/api-urls';
import { Address } from 'src/app/types/address.model';
import { CustomerMaster, CustomerOption } from 'src/app/types/customer-option.model';
import { Observable, forkJoin } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { customerSelector } from '../../store/customer/customer.selectors';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseSubscriptionComponent {
  private _customer: CustomerOption | null;

  constructor(
    private api: ApiService,
    private store: Store<AppStateInterface>,
    private permissionService: PermissionService
  ) {
    super();
  }

  public get customer(): CustomerOption {
    this.store
      .pipe(select(customerSelector))
      .pipe(takeUntil(this.destroyed))
      .subscribe((customer) => {
        this._customer = customer;
      });
    return this._customer as CustomerOption;
  }

  public getShipTos(customerNumber: any, shipTo?: string, skipPermissionCheck?: boolean, exactMatch?: boolean) {
    let useShipTo = shipTo || '';

    if (!skipPermissionCheck) {
      useShipTo = this.permissionService.canViewAllShipTo() ? '' : shipTo || '';
    }
    let params = {
      companyNumber: environment.companyNumber,
      customerNumber,
      shipTo: useShipTo,
      exactMatch,
    };
    return this.api.getAll(API_URL.ErpShipTo, params, true);
  }

  public getCustomerMaster(companyNumber: string | number, customerNumber: string) {
    companyNumber = companyNumber.toString();
    let params = {
      companyNumber,
      customerNumber,
    };
    return this.api.get(API_URL.ErpCustomerMaster, params, true);
  }

  public getCustomerAddresses(userId: number) {
    let params = {
      order: {
        id: 'desc',
      },
      addressStatusId: 1,
      userId: userId,
    };
    return this.api.getAll(API_URL.CustomerAddress, params, false);
  }

  /**
   * Combining all the calls we possibly have to make for the address list page into one method stored here. This way
   * we keep the component more clean and only need to subscribe to this for the value alone.
   *
   * @param userId
   * @param customerNumber
   * @param shipTo
   * @returns
   */
  public getAllAddresses(
    userId: number,
    customerNumber: string,
    shipTo: string,
    skipPermissionCheck: boolean = false
  ): Observable<Address[]> {
    let customerAddresses: Address[] = [];

    return new Observable<Address[]>((subscriber) => {
      forkJoin([
        this.getShipTos(customerNumber, shipTo, skipPermissionCheck, true),
        this.getCustomerAddresses(userId),
      ]).subscribe({
        next: (response: any) => {
          let address: Address;
          if (!response[0].sxeShipTo.length) {
            // Handle the case if shipTo returns an empty array.
            address = this.structureDefaultAddress(this.customer.customerMaster, true);
          } else {
            address = this.structureDefaultAddress(response[0]);
          }
          if (address) {
            customerAddresses.push(address);
          }

          // format customer addresses the user has added.
          response[1]['hydra:member'].forEach((address: any) => {
            customerAddresses.push(address);
          });
          subscriber.next(customerAddresses);
          subscriber.complete();
        },
        error: (error: string) => {
          console.log('error: ', error);
          subscriber.error(error);
        },
      });
    });
  }

  /**
   * Just a sanitize method to make sure we have the correct data structure for the address based on the different sxe
   * response objects.
   *
   * @param response
   * @param isFromCustomerMaster
   * @returns
   */
  public structureDefaultAddress(response: any, isFromCustomerMaster: boolean = false) {
    const data = isFromCustomerMaster ? response : response.sxeShipTo[0];
    if (isFromCustomerMaster) {
      data.addr = [data.address1, data.address2, data.address3].filter((val) => !!val).join(', ');
    } else if (data.addr3 && !data.addr.includes(data.addr3)) {
      // Make sure addr3 is included, if it exists
      data.addr = [data.addr, data.addr3].filter((val) => !!val).join(', ');
    }

    return {
      name: data.name,
      address1: data.addr.replace(';', ', '),
      city: data.city,
      postalCode: data.zipcd,
      province: data.state,
      isDefault: true,
    };
  }

  public structureCustomerMasterData(customer: any): CustomerMaster {
    // TODO - better handling for use case where no customer data exists?
    if (!customer) {
      return {};
    }

    // Reformat, as needed
    return {
      address1: customer.addr1,
      address2: customer.addr2,
      address3: customer.addr3,
      ...customer,
    };
  }
}
