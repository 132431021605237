import { Component, Input, OnInit } from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductAttachmentModalComponent } from '../product-attachment-modal/product-attachment-modal.component';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss'],
})
export class ProductDocumentsComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() product: ProductResult;
  @Input() index?: number = 0;

  constructor(private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {}

  open() {
    const modalRef = this.modalService.open(ProductAttachmentModalComponent);
    modalRef.componentInstance.product = this.product;
  }
}
