<div
  *ngIf="products.length"
  class="product-list mb-4 {{ pageType }} details"
  [ngClass]="{ grid: isGrid, 'show-other-details': collapse?.collapsed }"
>
  <div class="list-header val-th">
    <div class="col pro-details" [ngClass]="isOrderDetail ? 'w-50' : 'w-100'">Product Details</div>
    <div class="col ordered text-start justify-content-start" *ngIf="isHistory">Qty. Ordered</div>
    <div
      class="col inventory text-start justify-content-start"
      *ngIf="!isImportOrder && !isQuote && !isQuoteDetails"
    >
      Inventory
    </div>
    <div *ngIf="isOrderDetail" class="col orderQty">Ordered</div>
    <div *ngIf="isOrderDetail" class="col orderQty">Backorder</div>
    <div *ngIf="isOrderDetail" class="col orderQty">Shipped</div>
    <div class="col price" [ngClass]="{ invisible: !canViewPrices }">Price</div>
    <div *ngIf="!isOrderDetail" class="col qty">Quantity</div>
    <div class="col total" *ngIf="showTotal">Total</div>
  </div>

  <div class="list-wrapper">
    <div
      *ngFor="let product of products; trackBy: trackByProductId; let idx = index"
      class="list-item val-td"
      [id]="'product-' + idx"
    >
      <val-item-tags
        [user]="user"
        [product]="product"
        [quickListHeaderId]="quickListHeaderId"
        class="plp"
      ></val-item-tags>
      <div class="list-content">
        <div
          class="col checkbox"
          *ngIf="(!isImportOrder && !isQuote) || (isQuoteDetails && canShowAddToCart())"
          [ngClass]="{ invisible: !product.price }"
        >
          <label class="custom-checkbox">
            <input
              type="checkbox"
              name="checked_elements"
              [value]="product.itemId"
              class="custom-input"
              [checked]="product.isSelected || false"
              (change)="onCheckItem($event, product)"
              [disabled]="disabledCart(product)"
              [attr.data-testid]="DATA_TEST_ID.product.itemList.checkbox + '-' + idx | idFormatter"
            />
            <span class="custom-check"></span>
          </label>
        </div>
        <div class="col pro-details">
          <div class="product-img" [ngClass]="{ 'import-product-image': isImportOrder }">
            <a
              [routerLink]="productUrl(product)"
              [attr.data-testid]="DATA_TEST_ID.product.itemList.imageLink + '-' + idx | idFormatter"
            >
              <img [src]="product.image || '/assets/img/no-image-en.png'" alt="product image" fallbackImage/>
            </a>
          </div>
          <div class="text">
            <div class="item-id">
              <a
                *ngIf="product.seoUrl || product.url; else itemIdText"
                [routerLink]="product.seoUrl || product.url"
                [attr.data-testid]="DATA_TEST_ID.product.itemList.titleLink + '-' + idx | idFormatter"
              >{{ product.itemId }}</a
              >
              <ng-template #itemIdText>
                {{ product.itemId }}
              </ng-template>
            </div>
            <div class="item-title">
              <a
                *ngIf="product.seoUrl || product.url; else itemTitleText"
                [routerLink]="product.seoUrl || product.url"
                [attr.data-testid]="DATA_TEST_ID.product.itemList.idLink + '-' + idx | idFormatter"
              >{{ product.title }}</a
              >
              <ng-template #itemTitleText>
                {{ product.title }}
              </ng-template>
            </div>

            <div
              class="personal-code"
              *ngIf="
                !(
                  (this.quickListHeaderId && this.isHistory && !product.isInAlgolia) ||
                  !this.isBuyer ||
                  this.isCheckout ||
                  !this.quickListHeaderId ||
                  this.isImportOrder
                )
              "
            >
              <div *ngIf="canUpdateQuickList || hasPersonalCode(product)" class="specs">
                <span class="spec-name">Personal code:</span>
                <span class="spec-value">
                  <a *ngIf="canUpdateQuickList; else readOnlyPersonalCode"
                     [attr.data-testid]="DATA_TEST_ID.product.itemList.personalCode + '-' + idx | idFormatter"
                     (click)="showPersonalCode(product.itemId)"
                  >
                    {{
                    quickListHeaderId && product.personalCodes && product.personalCodes[quickListHeaderId]
                      ? product.personalCodes[quickListHeaderId]
                      : "Set personal code"
                    }}
                  </a>
                  <ng-template #readOnlyPersonalCode>
                    {{ product.personalCodes[quickListHeaderId] }}
                  </ng-template>
                </span>
              </div>
              <val-personal-code
                [toggle]="true"
                (closePersonalCode)="closePersonalCode()"
                [product]="product"
                [quickListHeaderId]="quickListHeaderId"
                *ngIf="
                  canUpdateQuickList &&
                  quickListHeaderId &&
                  (personalCodeService.isPersonalCodeVisible$ | async) &&
                  (personalCodeService.activeItemId$ | async) === product.itemId
                "
              ></val-personal-code>
            </div>

            <div class="other-details" *ngIf="!isImportOrder">
              <div class="specs" *ngIf="product.manufacturer">
                <span class="spec-name">Manufacturer:</span>
                <span class="spec-value">{{ product.manufacturer }}</span>
              </div>
              <div class="specs" *ngIf="product.manufacturerPartNo">
                <span class="spec-name">Manufacturer #:</span>
                <span class="spec-value">{{ product.manufacturerPartNo }}</span>
              </div>
              <val-product-documents [product]="product" [index]="idx"></val-product-documents>
            </div>
          </div>
        </div>

        <div class="col ordered data text-start py-1" *ngIf="isHistory">
          <span class="_labels">Qty Ordered:</span>
          <span class="qty">{{ product.totalItemsOrdered | number : "1.0-0" }}</span>
          <span class="supp">{{ product.totalOrdersPlaced | number : "1.0-0" }} Orders</span>
        </div>
        <div
          class="col inventory data text-start py-1"
          *ngIf="!isImportOrder && !isQuote && !isQuoteDetails"
        >
          <span class="_labels">Inventory:</span>
          <div class="inv status">
            <val-product-status
              [isCart]="isCart"
              [product]="product"
              [b2b]="isBuyer"
              [index]="idx"
            ></val-product-status>
          </div>
        </div>

        <div class="col orderQty data" *ngIf="isOrderDetail">
          <span class="_labels">Ordered:</span>
          <span class="qty">{{ product.qtyOrdered }}</span>
        </div>

        <div class="col orderQty data" *ngIf="isOrderDetail">
          <span class="_labels">Backorder:</span>
          <span class="qty">{{ product.qtyBackordered }}</span>
        </div>

        <div class="col orderQty data" *ngIf="isOrderDetail">
          <span class="_labels">Shipped:</span>
          <span class="qty">{{ product.qtyShipped }}</span>
        </div>

        <div class="col price data" [ngClass]="{ invisible: !canViewPrices }">
          <div *ngIf="isLoading.price" class="spinner"></div>
          <val-product-price
            *ngIf="!isLoading.price"
            [product]="product"
            [isLoading]="isLoading.price"
            [isList]="!isGrid"
            [index]="idx"
          ></val-product-price>
        </div>

        <div *ngIf="!isOrderDetail" class="col qty data">
          <span class="_labels">Quantity:</span>
          <val-product-quantity
            [product]="product"
            [isLoading]="isLoading.price"
            (updateCartQty)="updateCartItem($event, idx)"
            [index]="idx"
            [isQuoteDetails]="isQuoteDetails"
            [disabled]="
              product
                | disableQuantity
                  : isCheckout
                  : isOrderDetail
                  : isLoading
                  : disableEdit
                  : isHistory
            ">
          </val-product-quantity>
          <span
            class="alert-warning field-validation-error"
            *ngIf="!isQuote && product.qty % product.sellMult !== 0 && !isLoading.price && product.price"
          >
            <span [attr.data-testid]="DATA_TEST_ID.product.quantity.error + '-' + idx | idFormatter">
              Quantity must be in multiples of {{ product.sellMult }}
            </span>
          </span>
        </div>

        <div class="col total" *ngIf="showTotal" [ngClass]="{ data: !isLoading.price }">
          <span class="_labels">Total:</span>
          <div
            *ngIf="isLoading.total"
            class="spinner"
            [attr.data-testid]="DATA_TEST_ID.product.total.loading + '-' + idx | idFormatter"
          ></div>
          <span
            *ngIf="!isLoading.total"
            class="price"
            [attr.data-testid]="DATA_TEST_ID.product.total.value + '-' + idx | idFormatter"
          >${{ product.price * product.qty | number : "1.2-2" }}</span
          >
        </div>
      </div>
      <div class="list-footer">
        <div
          class="col add-to-cart"
          *ngIf="!isImportOrder && !isCart && !disableEdit && !isQuote && !isQuoteDetails"
        >
          <button
            type="button"
            class="btn btn-sm btn-primary"
            [disabled]="disabledCart(product)"
            (click)="addToCart(product)"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.addToCart + '-' + idx | idFormatter"
          >
            Add to cart
          </button>
        </div>
        <div class="remove" *ngIf="isCart && !disableEdit">
          <button
            type="button"
            class="btn btn-outline-link"
            (click)="removeFromCart(idx)"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.removeFromCart + '-' + idx | idFormatter"
          >
            Remove
          </button>
        </div>
        <div class="remove" *ngIf="isQuote">
          <button
            type="button"
            class="btn btn-outline-link"
            (click)="isImportOrder ? removeFromImportOrder(idx) : removeFromCart(idx)"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.removeFromCart + '-' + idx | idFormatter"
          >
            Remove
          </button>
        </div>
        <div class="product-notes col-8" *ngIf="isQuote || isQuoteDetails">
          <label for="productNotes-{{ idx }}">Product notes: </label>
          <textarea
            id="productNotes-{{ idx }}"
            class="form-control"
            rows="1"
            [disabled]="disableEdit"
            [value]="product?.productNotes || ''"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.productNotes + '-' + idx | idFormatter"
            (change)="updateCartItem(product, idx)"
          ></textarea>
        </div>
        <div class="col-4 d-flex align-items-end align-self-end flex-column">
          <div
            *ngIf="product.qtyRevision"
            class="d-flex alert alert-primary py-1 px-2 rounded w-50 justify-content-center"
            role="alert"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.quantityUpdatedAlert + '-' + idx | idFormatter"
          >
            Quantity is updated
          </div>
          <div
            *ngIf="product.itemRemoved"
            class="d-flex alert alert-danger py-1 px-2 rounded w-50 justify-content-center"
            role="alert"
            [attr.data-testid]="DATA_TEST_ID.product.itemList.quantityRemoveAlert + '-' + idx | idFormatter"
          >
            Product is removed
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
