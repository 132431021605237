<div class="title">Subscribe to Vallen Emails</div>
<p>Get up-to-date information on what Vallen offers</p>
<div class="form-group" [formGroup]="form">
  <div class="input-group">
    <input type="email" class="form-control" id="email" [formControlName]="'email'" placeholder="Enter email address" />
    <div class="input-group-append">
      <button class="btn" type="submit" (click)="onSubmit()" [disabled]="!form.valid">
        <i class="material-icons-outlined">arrow_forward</i>
      </button>
    </div>
  </div>
</div>
